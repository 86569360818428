/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.vertialBar {
	height: 100%;
	width: 1px;
	background-color: rgb(231, 231, 231);
}


.rdw-editor-main {
	height: 100%;
	overflow: auto;
	box-sizing: border-box;
	border: 1px solid #e2e2e2;
	min-height: 230px;
}

.bg-t1 {
	background-size: cover;
	background-image: url("https://www.ceipequenoanjo.com.br/wp-content/uploads/2022/09/banner-cei.jpg");
	background-repeat: no-repeat;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cardlogin{
	background: rgba(255, 255, 255, 0.6) !important;
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
	backdrop-filter: blur(5px) !important;
	-webkit-backdrop-filter: blur(5px) !important;
	border: 1px solid rgba(255, 255, 255, 0.3) !important;

}

.textocard{
    color: #000 !important;
}

.buttonlogin{
	background-color: #011b3a !important;
	border-radius: 100px;
	font-size: 16px;
	padding: 12px !important;
	transition: 0.6s;
	border: solid 1px #011b3a;
}
.buttonlogin:hover{
	background-color: transparent !important;
	border-radius: 100px;
	font-size: 16px;
	padding: 6px;
	transition: 0.6s;
	border: solid 1px #011b3a;
}

.inputlogin{

}

.label{
	color: #000000 !important;
}

p {
	margin: 0 !important;
}

h4 {
	margin: 0 !important;
}

h5 {
	margin: 0 !important;
}

.stylecard {
	border-radius: 5px;
	color: #dadada;
	margin: 10px;
	background: linear-gradient(118deg, #282828, #424242);
	padding: 15px;
}

[dir] .modal .modal-content {
	box-shadow: 0 5px 20px 0 rgb(34 41 47 / 10%);
	background: #ffffff !important;
	border-radius: 20px ;
}

[dir] .modal .modal-header {
	background: #ffffff !important;
}

.modal {
	background: rgba(0, 0, 0, 0.5);
	padding-top: 50px;
}

[dir] .row {
	margin-top: calc(var(--bs-gutter-y) * -1);
	justify-content: center !important;
}

[dir] .card {
	background-color: #fff;
	background-clip: border-box;
	border: 0 solid rgba(34, 41, 47, 0.125);
	border-radius: 30px !important;
	text-align: center;
}


